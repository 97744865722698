<template>
  <div>
    <h5 class="font-weight-regular wsACCENT mb-2">
      {{ $t('courses.description.categories.title') }}
    </h5>

    <div :style="`border : 1px solid var(--wsDARKLIGHT)`" class="wsRoundedLight">
      <div  class="d-flex  align-center flex-wrap flex-row px-3 py-1">

        <v-sheet max-height="24px" v-for="(item,i) in selectedCategories" :key="`selected${i}`"
                 class="mr-2 my-1  d-flex align-center px-2 py-1 wsRoundedLight" :color="wsLIGHTCARD">
          <h5 class="wsACCENT font-weight-regular mr-2" >
            {{ categoriesObject[item] ? categoriesObject[item].text : item }}
          </h5>
          <v-hover #default="{hover}">
            <v-icon
                @click="selectCategory(categoriesObject[item])"
                :color="wsACCENT"
                :style="`background : ${hover ? wsBACKGROUND : 'transparent'}`"
                class="wsRoundedLight"
                small
            >
              mdi-close
            </v-icon>
          </v-hover>
          </v-sheet>
        <input type="text"
               v-model="search"
               @focus="expanded = true"
               v-click-outside="() => handleCollapse()"
               @click="expanded = true"
               class="flex-grow-1 my-1"
               :placeholder="$t('courses.description.categories.search')"
               style=" width: 150px; outline: none"
        >
        <!--      <ws-text-field-->
        <!--          v-model="search"-->
        <!--          @focus="expanded = true"-->
        <!--          @blur="handleCollapse"-->
        <!--          @click="expanded = true"-->
        <!--          solo-->
        <!--          flat-->
        <!--          background-color="red"-->
        <!--          placeholder="enter"-->
        <!--          height="24px"-->
        <!--      />-->
      </div>

      <v-expand-transition>
        <div
            v-if="expanded"
            @mouseover="preventExpand = true"
            @mouseleave="preventExpand = false"
            class=" mt-2 "
            style="border-top: 1px solid var(--wsBACKGROUND); "
        >
          <div class="d-flex justify-space-between align-center pa-3 py-2">
            <h5 class="wsDARKER ">{{ $t('courses.description.categories.choose') }}:</h5>
            <v-icon @click="expanded = false" :color="wsACCENT">mdi-close</v-icon>
          </div>

          <div style="max-height: 220px" class="overflow-y-auto">
            <v-hover #default="{hover}" v-for="(category,i) in categoriesFiltered"  :key="i">
              <v-sheet
                  @click="selectCategory(category)"
                  :style="`border-top: 1px solid var(--wsBACKGROUND) !important;`"
                  :color="!hover ? 'transparent' : wsLIGHTCARD"
                  class="py-3 px-3 pointer"
              >
                <div  class="d-flex align-center"
                      :class="[{'pl-7' : !!category.parent && !search}]"
                >
                  <v-icon :color="wsACCENT" size="18" class="mr-3">mdi-checkbox-{{ selectedCategories.includes(category.value) ? 'marked' : 'blank' }}-outline</v-icon>
                  <h5 class="wsACCENT  text-no-wrap"
                      :class="[{'font-weight-regular' : !!category.parent || !!search }]"
                  >
                    {{ category.text }}
                  </h5>
                </div>

              </v-sheet>
            </v-hover>
          </div>

          <div v-if="!categoriesFiltered.length"
               style="border-top: 1px solid var(--wsBACKGROUND); "
               class="px-3 py-6">
            <h5 class="font-weight-regular wsACCENT text-center">{{ $t("courses.description.categories.search_no_result") }}: <span class="font-weight-bold">"{{search}}"</span></h5>
          </div>



        </div>
      </v-expand-transition>
    </div>

  </div>

</template>

<script>


export default {
  name: "wsCourseCategoriesSelector",
  props : {
    value : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      search : null,
      preventExpand : false,
      expanded : false,
      category : null,
      selectedCategories : [],
      categories : [
        { text : "Business" , value : 'Business' },
        { text : "IT" , value : 'IT' },
        { text : "Art" , value : 'Art' },
        { text : "Business" , value : 'Business2' },
        { text : "IT" , value : 'IT2' },
        { text : "Art" , value : 'Art2' }
      ]
    }
  },
  computed : {
    categoriesObject() {
      let object = {}
      this.categories.forEach( item => {
        object[item.value] = item
      })
      return object
    },
    categoriesFiltered() {
      let items = this.COPY(this.categories)

      items = items.filter( el => !el.parent)
      let parentItems = this.COPY(items).filter(el => this.selectedCategories.includes(el.value))

      if (this.search) {
        items = this.categories.filter( el => el.text.toLowerCase().includes(this.search.toLowerCase().trim()))

      } else {

        parentItems.forEach( item => {
          let index = items.findIndex(el => el.value === item.value)
          items.splice(index+1, 0, ...this.getChildrenCategories(item.value))
        })

      }
      return items
    }
  },
  watch : {
    selectedCategories : {
      handler() {
        if (!this.EQ(this.value, this.selectedCategories)) {
          this.$emit('input', [...this.selectedCategories])
        }
      },
      deep: true
    },
    value() {
      if (!this.EQ(this.value, this.selectedCategories)) {
        this.selectedCategories = [...this.value]
      }
    }
  },
  methods : {
    getChildrenCategories(parent) {
      return this.categories.filter( el => el.parent === parent) || []
    },
    handleCollapse() {
      if (this.preventExpand) {
        return
      }
      this.expanded = false
    },
    selectCategory(category) {
      if (this.selectedCategories.includes(category.value)) {
        this.selectedCategories = this.selectedCategories
            .filter(el => el !== category.value)
            .filter(el => this.categoriesObject[el] && this.categoriesObject[el].parent !== category.value)
      } else {
        this.search = null
        if (!!category.parent && !this.selectedCategories.includes(category.parent)) {
          this.selectedCategories.push(category.parent)
        }
        this.selectedCategories.push(category.value)
      }
      this.$emit('input', [...this.selectedCategories])
    },
    async getCategories() {
      this.$store.state.courses.marketCategories = (await this.$store.dispatch('courses/GET_SYSTEM_CATEGORIES'))['items']
    }
  },
  async mounted() {
    if (!this.$store.state.courses.marketCategories.length) {
      await this.getCategories()
    }
    this.categories = this.$store.state.courses.marketCategories
    if ( this.value ) {
      this.selectedCategories = this.value
    }
  }
}
</script>

<style scoped>

</style>
