<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Description')"
      icon="mdi-image-text"
  >
    <portal to="dash_course.header">
      <ws-button
          @click="displayPreview = true"
          label="Preview"
          outlined
          left-icon="mdi-eye"
          :color="wsACCENT"
          class="mr-2"
      />
      <ws-button
          @click="saveChanges"
          label="Save"
      />
    </portal>
    <ws-dialog
        v-if="displayPreview"
        v-model="displayPreview"
        outlined
        fullscreen
        background-color="wsACCENT"
        no-padding
    >
      <template #header>
        <div></div>
      </template>
      <template #default>
        <marketCourse
            :course="entityData" />
      </template>
      <template #footer>
        <v-sheet
            :color="wsLIGHTCARD"
            style="border-top : 1px solid var(--wsBACKGROUND)"
            class="d-flex justify-end px-6 py-3"
        >
          <ws-button label="Close" @click="displayPreview = false" outlined></ws-button>
        </v-sheet>
      </template>

    </ws-dialog>
    <v-sheet class="wsRoundedHalf mt-6">

<!--      <h3 class="wsDARKER mb-6">-->
<!--        {{ $t('courses.description.general') }}-->
<!--      </h3>-->
      <v-row>
        <!-- Image-->
        <v-col cols="12" md="6">
          <h5 class="font-weight-regular wsACCENT mb-2">
            {{ $t('CourseImage') }}
          </h5>
          <image-param-picker
              v-model="entityData.img"
              no-border
          />
        </v-col>
        <v-col cols="12" md="6">
          <h5 class="font-weight-regular wsDARKLIGHT" :class="[{'mt-6' : !SM}]">
            {{ $t('courses.description.image.tooltip') }}
          </h5>
        </v-col>

        <!-- Course Name -->
        <v-col cols="12" md="6">
          <ws-text-field
              :label="$t('courses.description.name.title')"
              :placeholder="$t('courses.description.name.placeholder')"
              v-model="entityData.name"
          />
        </v-col>
        <v-col v-if="!SM" cols="12" md="6"></v-col>

        <v-col cols="12" md="6">
          <ws-course-categories-selector
              v-model="entityData.categories"
          />
        </v-col>
        <v-col v-if="!SM" cols="12" md="6"></v-col>

        <!-- Online Payment Info -->
        <template   v-if="HAS_ONLINE_PAYMENT">
          <v-col cols="12" md="6">
            <ws-text-field
                v-model="entityData.payment_name"
                :label="$t('FiscalizationName')"
                :placeholder="$t('EnterFiscalizationName')"
                clearable
            />
          </v-col>
          <v-col cols="12" md="6">
            <h5 class="font-weight-regular wsDARKLIGHT" :class="[{'mt-6' : !SM}]">
              {{ $t('FiscalizationNameTooltip') }}
            </h5>
          </v-col>

          <v-col cols="12" md="6">
            <ws-text-field
                v-model="entityData.product_id"
                :label="$t('FiscalizationId')"
                :placeholder="$t('EnterFiscalizationId')"
                clearable
            />
          </v-col>

          <v-col cols="12" md="6">
            <h5 class="font-weight-regular wsDARKLIGHT" :class="[{'mt-6' : !SM}]">
              {{ $t('FiscalizationIdTooltip') }}
            </h5>
          </v-col>

        </template>

        <!-- Short Description-->
        <v-col cols="12" md="6">
          <ws-text-field
              v-model="entityData.short_description"
              :label="$t('ShortDescription')"
              :placeholder="$t('EnterShortDescription')"
              height="100"
              counter="200"
              area
              clearable
          />
        </v-col>
        <v-col cols="12" md="6"></v-col>

      </v-row>


<!--      <h3 class="wsDARKER mb-6">-->
<!--        {{ $t('courses.description.advanced') }}-->
<!--      </h3>-->

      <v-row>

        <!-- Video-->
<!--        <v-col cols="12" md="6">-->
<!--          <h5 class="font-weight-regular wsACCENT mb-2">-->
<!--            {{ $t('courses.description.video.title') }}-->
<!--          </h5>-->
<!--          <image-param-picker-->
<!--              v-model="entityData.video"-->
<!--              no-border-->
<!--          />-->
<!--        </v-col>-->
<!--        <v-col cols="12" md="6">-->
<!--          <h5 class="font-weight-regular wsDARKLIGHT" :class="[{'mt-6' : !SM}]">-->
<!--            {{ $t('courses.description.video.tooltip') }}-->
<!--          </h5>-->
<!--        </v-col>-->

        <!-- Features-->
        <v-col cols="12" md="6">
          <course-description-features
              v-model="entityData.features"
          />
        </v-col>

        <v-col cols="12" md="6">
          <h5 class="font-weight-regular wsDARKLIGHT" :class="[{'mt-6' : !SM}]">
            {{ $t('courses.description.features.tooltip') }}
          </h5>
        </v-col>

        <!-- Description -->
        <v-col cols="12" md="6">
          <h5 class="font-weight-regular wsACCENT mb-2">
            {{ $t('courses.description.description.title') }}
          </h5>
          <v-sheet min-height="200" :style="`border : 1px solid ${wsDARKLIGHT}`" class="wsRoundedLight pt-3  pb-7">
            <ws-text-editor
                v-model="entityData.description"
                :html="entityData.description_text"
                :value="[]"
                is-public
            />
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
          <h5 class="font-weight-regular wsDARKLIGHT" :class="[{'mt-6' : !SM}]">
            {{ $t('courses.description.description.tooltip') }}
          </h5>
        </v-col>

      </v-row>

      <v-sheet height="150"></v-sheet>


    </v-sheet>
  </sub-page>



</template>

<script>

import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";
import courseDescriptionFeatures from "@/components/pages/westudy/admin/course/UI/courseDescriptionFeatures.vue";
import wsCourseCategoriesSelector from "@/components/pages/westudy/admin/course/UI/wsCourseCategoriesSelector.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import marketCourse from "@/modules/market/components/Course/MarketCourse"
export default {
  name: "courseDescription",
  props : ['uuid'],
  components : {
    imageParamPicker,
    courseDescriptionFeatures,
    wsCourseCategoriesSelector,
    marketCourse
  },
  data() {
    return {
      displayPreview : false,
      uploadLogo : false,
      entityData : {
        short_description : '',
        description : [],
        name : ''
      }
    }
  },
  computed : {
  ...mapState('courses', [ 'course' , 'selectedLang' ]),
  },
  watch : {
    course(val) {
      this.entityData = JSON.parse(JSON.stringify(val))
    },
    selectedLang() {
      this.initPage()
    }
  },

  methods : {
    ...mapActions('courses', [ 'ADD_COURSE' , 'GET_COURSE' , 'EDIT_COURSE_IMAGE' ]),
    ...mapMutations('courses' , ['INIT_PAGE']),
    ...mapActions('upload', [ 'UPLOAD_COURSE_IMAGE',  ]),

    async updateCourseImage($event) {

      let data = {
        course_id : this.uuid,
        file_id : $event.uuid
      }
      let result = await this.EDIT_COURSE_IMAGE(data)
      if ( !result ) {
        return
      }
      this.$store.state.courses.course.img = $event.url
      this.uploadLogo = false
    },

    async updateLogo(file) {
      let data = {
        file : file,
        data : {
          course : this.uuid
        },
        entityDataDefault : {
          name : {},
          description : {},
          short_description: {},
          img : null,
        }
      }
      let result = await this.UPLOAD_COURSE_IMAGE(data)
      if ( !result ) { return this.notify('ConnectionError') }

      this.$store.state.courses.course.img = result.url
      this.uploadLogo = false
    },

    async saveChanges() {

      this.entityData.lang = this.selectedLang

      let result = await this.ADD_COURSE(this.entityData)
      if (!result) { return this.notify( this.$t('NetworkError') )}
      this.notify(this.$t('ChangesSaved'))
      this.$store.state.courses.course = result;

    },
    async initPage() {

      let result = await this.GET_COURSE({ uuid : this.uuid, lang : this.selectedLang} )
      if ( !result ) {
        return
      }
      this.entityData = result

    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>